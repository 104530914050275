<template>
  <div v-loading="loading">
    <div class="door">
      <div class="flex_between bbe9s">
        <div class="title f-left p10">任务信息</div>
        <div class="flex_start">
          <div class="flex_start mr20">
            <el-button type="info" style="height: 40px" @click="godetail"
              >查看订单详情</el-button
            >
            <el-button
              v-if="wiahulema2"
              type="success"
              @click="waihu('dian')"
              style="height: 40px"
              >外呼{{ waihudian.username }}店长</el-button
            >
            <el-button
              v-else-if="!wiahulema2"
              type="danger"
              style="height: 40px"
              >呼叫中</el-button
            >
            <el-button
              v-if="wiahulema"
              type="warning"
              @click="waihu"
              style="height: 40px"
              >外呼客户</el-button
            >
            <el-button v-else-if="!wiahulema" type="danger" style="height: 40px"
              >呼叫中</el-button
            >
          </div>
          <div style="width: 53px">
            <span
              class="f13 mt30 Shrink"
              @click="Shrink(0)"
              style="color: #aba9a9"
              >{{ isindex[0].msg }}</span
            >
          </div>
        </div>
      </div>
      <!-- 信息内容 -->
      <div
        class="flex_column f14 bbe9s pb20 mt10"
        :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
      >
        <div class="p5">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="8">
              <div class="flex_start mr20 flex_items mt10">
                <span class="mr10 t1sp">客户姓名:</span>
                <span>{{ infos2.contacts }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex_start mr20 flex_items mt10">
                <span class="mr10 t1sp">联系方式:</span>
                <span>{{ infos2.telephone }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex_start flex_items mt10">
                <span class="mr10 t1sp">家庭地址:</span>
                <div>{{ infos2.addres }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="flex_start flex_items p5">
          <span class="mr10 t1sp">添加微信:</span>
          <div style="width: 574px">
            <span v-if="infos2.wechat == 0">否</span>
            <span v-else>是</span>
          </div>
        </div>
        <div class="flex_start flex_items p5">
          <span class="mr10 t1sp">备注信息:</span>
          <div style="width: 574px">{{ infos2.remarks }}</div>
        </div>
      </div>
      <div>
        <span
          class="f13 mt10 Shrink"
          @click="Shrink(1)"
          style="color: #aba9a9"
          >{{ isindex[1].msg }}</span
        >
      </div>
      <!-- 操作记录 -->
      <div class="flex_between mt10 bbe9s">
        <div class="title f-left p10">操作记录</div>
      </div>
      <!-- 内容 -->
      <div
        class="flex_column f14 bbe9s pb20 mt10"
        :style="{ display: isindex[1].tt == 't1' ? 'none' : 'flex' }"
      >
        <div class="p5">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="8">
              <div class="flex_start mr20 flex_items">
                <span class="mr10 t1sp">接单店长:</span>
                <span>{{ infos2.username }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex_start mr20 flex_items">
                <span class="mr10 t1sp">订单状态:</span>
                <span>{{ infos2.states }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="flex_start mr20 flex_items">
                <span class="mr10 t1sp">上门时间:</span>
                <span>{{ infos2.planned }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="flex_start flex_items p5">
          <span class="mr10 t1sp">报价信息:</span>
          <div>总价{{ infos2.totalprice }}元</div>
        </div>
        <!-- 店长反馈记录 -->
        <!-- <div class="flex_column pb10 mt10">
        <div class="title f-left p10 bbe9s">店长反馈记录</div>
        <div class="mt20 innerkuang">
          <el-table :data="infos2.through1" border>
            <el-table-column prop="admin_id" label="店长"></el-table-column>
            <el-table-column prop="th_time" label="时间"></el-table-column>
            <el-table-column prop="mode" label="方式"></el-table-column>
            <el-table-column prop="remar" label="沟通内容"></el-table-column>
          </el-table>
        </div>
        </div>-->
        <!-- 客服跟进记录 -->
        <div class="flex_column pb10 mt10 f16">
          <div class="title f-left p10 bbe9s">客服跟进记录</div>
          <div class="mt20 innerkuang">
            <el-table :data="infos2.through" border>
              <el-table-column prop="th_time" label="时间"></el-table-column>
              <el-table-column prop="mode" label="方式"></el-table-column>
              <el-table-column label="处理人">
                <template slot-scope="scope">
                  <div>
                    <span v-if="scope.row.role == 1"
                      >{{ scope.row.username }}/客服</span
                    >
                    <span v-else>{{ scope.row.username }}/店长</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="是否接通">
                <template slot-scope="scope">
                  <span v-if="scope.row.conversation == 0">是</span>
                  <span v-if="scope.row.conversation == 2">否</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="end_time"
                label="下次跟进时间"
              ></el-table-column>
              <el-table-column prop="remar" label="沟通内容"></el-table-column>
              <el-table-column label="沟通录音">
                <template slot-scope="scope">
                  <div v-if="scope.row.record.length == 0">暂无录音</div>
                  <div v-else>
                    <audio
                      style="width: 100%; outline: none"
                      :src="scope.row.record[0].monitorfilename"
                      controls="controls"
                    ></audio>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div>
        <span
          class="f13 mt10 Shrink"
          @click="Shrink(2)"
          style="color: #aba9a9"
          >{{ isindex[2].msg }}</span
        >
      </div>
      <!-- 任务操作-上门跟进 -->
      <div class="flex_between pb10 mt10 bbe9s">
        <div class="title f-left p10">任务操作-上门跟进</div>
      </div>
      <!-- 内容 -->
      <div
        class="flex_warp flex_items bbe9s f16 pb30"
        :style="{ display: isindex[2].tt == 't1' ? 'none' : 'flex' }"
      >
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">客户电话是否接通</span>
          <el-radio-group v-model="isok.istong" class="flex_items f14">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">是否守时</span>
          <el-radio-group v-model="isok.isshou" class="flex_items f14">
            <el-radio :label="1" @click.native.prevent="clickitem('isshou', 1)"
              >是</el-radio
            >
            <el-radio :label="2" @click.native.prevent="clickitem('isshou', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">是否工牌鞋套</span>
          <el-radio-group v-model="isok.isgongpai" class="flex_items f14">
            <el-radio
              :label="1"
              @click.native.prevent="clickitem('isgongpai', 1)"
              >是</el-radio
            >
            <el-radio
              :label="2"
              @click.native.prevent="clickitem('isgongpai', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">是否专业</span>
          <el-radio-group v-model="isok.iszhuan" class="flex_items f14">
            <el-radio :label="1" @click.native.prevent="clickitem('iszhuan', 1)"
              >是</el-radio
            >
            <el-radio :label="2" @click.native.prevent="clickitem('iszhuan', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">是否专业报价</span>
          <el-radio-group v-model="isok.isbaojia" class="flex_items f14">
            <el-radio
              :label="1"
              @click.native.prevent="clickitem('isbaojia', 1)"
              >是</el-radio
            >
            <el-radio
              :label="2"
              @click.native.prevent="clickitem('isbaojia', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 isok">是否分析原因</span>
          <el-radio-group v-model="isok.isyuan" class="flex_items f14">
            <el-radio :label="1" @click.native.prevent="clickitem('isyuan', 1)"
              >是</el-radio
            >
            <el-radio :label="2" @click.native.prevent="clickitem('isyuan', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="flex_start flex_items m-r-60 mt10" style="width: 100%">
          <span class="mr20 isok">话术推荐</span>
          <div style="width: 75%" class="huashu">
            {{ infos2.Colloquialism }}
          </div>
        </div>
        <div class="flex_start flex_items m-r-60 mt20">
          <span class="mr20 isok">下次跟进时间</span>
          <el-date-picker
            :clearable="true"
            v-model="isok.time1"
            type="datetime"
            placeholder="选择下次跟进时间"
          ></el-date-picker>
        </div>
        <!-- 转派店长 -->
        <div class="flex_start flex_items m-r-60 mt20">
          <span class="mr20 isok">转派店长</span>
          <el-select
            class="mr10"
            filterable
            v-model="isok.shopper"
            collapse-tags
            placeholder="选择转派店长"
          >
            <el-option
              v-for="item in shoppers"
              :key="item.user_id"
              :label="item.usernamestorename"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </div>
        <!-- 取消操作 -->
        <div class="flex_start flex_items m-r-60 mt10">
          <span class="mr20 mt10" style="width: 170px">取消操作</span>
          <div class="flex_warp mt10">
            <el-select
              v-model="isok.cancle"
              multiple
              collapse-tags
              placeholder="请选择取消原因"
              class="mr20"
            >
              <el-option
                v-for="item in cancles"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              class="quyu"
              v-model="isok.other"
              placeholder="请输入其他原因"
            ></el-input>
          </div>
        </div>
        <!-- 沟通内容 -->
        <div class="flex_start flex_items m-r-60 mt20">
          <span class="mr20" style="width: 173px">沟通内容</span>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入沟通内容"
            v-model="isok.textarea"
          ></el-input>
        </div>
      </div>
      <!-- 提交 -->
      <div class>
        <el-button class="tijiao" type="primary" @click="tijiao"
          >提交处理结果</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentPage3"],
  data() {
    return {
      loading: true,
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
      ],
      infos2: {},
      isok: {
        istong: 0,
        isshou: 1,
        isgongpai: 1,
        iszhuan: 1,
        isbaojia: 1,
        isyuan: 1,
        time1: "",
        textarea: "",
        shopper: "",
        cancle: [],
        other: "",
      },
      shoppers: [],
      cancles: [
        {
          value: "多次联系不上客户",
          label: "多次联系不上客户",
        },
        {
          value: "加微信不通过",
          label: "加微信不通过",
        },
        {
          value: "价格高了",
          label: "价格高了",
        },
        {
          value: "暂时不处理了",
          label: "暂时不处理了",
        },
        {
          value: "找其他人处理",
          label: "找其他人处理",
        },
        {
          value: "客户自己解决了",
          label: "客户自己解决了",
        },
        {
          value: "工期无法满足",
          label: "工期无法满足",
        },
        {
          value: "无店长接单",
          label: "无店长接单",
        },
        {
          value: "房东/装修公司/邻居/物业责任",
          label: "房东/装修公司/邻居/物业责任",
        },
        {
          value: "客户纯咨询",
          label: "客户纯咨询",
        },
      ],
      // 外呼
      wiahulema: true,
      wiahulema2: true,
      jige: "",
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      waihudian: { username: "", mobile: "" },
      isSaveState: true,
      ExternalData: "",
      call: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.$store.state.order_id,
          Colloquialism: 2,
          // order_id: "22"
        })
        .then((res) => {
          if (res.code == 200) {
            // this.infos = res.data;
            this.infos2 = res.data;
            this.shop();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长
    shop() {
      this.util
        .get(this.HOST + "/Common/user", {
          order_id: this.$store.state.order_id,
          city_id: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            // this.loading = false;
            this.dianzhangphone();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长号码名字
    dianzhangphone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 跳转详情
    godetail() {
      this.util.godetail(this.infos2);
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    // 单选点击取消
    clickitem(kind, e) {
      if (kind == "isshou") {
        this.isok.isshou = this.util.cancleradio(kind, e, this.isok.isshou);
      } else if (kind == "isgongpai") {
        this.isok.isgongpai = this.util.cancleradio(
          kind,
          e,
          this.isok.isgongpai
        );
      } else if (kind == "iszhuan") {
        this.isok.iszhuan = this.util.cancleradio(kind, e, this.isok.iszhuan);
      } else if (kind == "isbaojia") {
        this.isok.isbaojia = this.util.cancleradio(kind, e, this.isok.isbaojia);
      } else if (kind == "isyuan") {
        this.isok.isyuan = this.util.cancleradio(kind, e, this.isok.isyuan);
      } else {
      }
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
    tijiao() {
      let order_id = this.$store.state.order_id;
      let next = this.util.datatime(this.isok.time1);
      if (next == "NaN-NaN-NaN NaN:NaN:NaN") {
        // this.$message({
        next = "";
        //   type: "error",
        //   message: "请输入下次跟进时间"
        // });
        // return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务处理中...",
      });
      if (this.isok.other != "") {
        this.isok.cancle.push(this.isok.other);
      }
      this.util
        .post(this.HOST + "/Order/ProcessingTasks", {
          order_id: order_id,
          through_id: 0,
          user_id: this.isok.shopper,
          type: 2,
          reason: this.isok.isyuan,
          offer: this.isok.isbaojia,
          major: this.isok.iszhuan,
          connect: this.isok.istong,
          punctual: this.isok.isshou,
          shoecovers: this.isok.isgongpai,
          remar: this.isok.textarea,
          end_time: next,
          cc_id: this.$store.state.cc_id,
          cancle_reason: this.isok.cancle.toString(","),
          Call_record: this.call,
          sms1: 1,
          sms: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
<style>
.t1sp {
  width: 77px;
  color: #3f51b5;
}
.Shrink {
  cursor: pointer;
  float: right;
  display: inline-block;
}
.innerkuang {
  height: 140px;
  overflow-y: scroll;
}
.isok {
  width: 130px;
}
.tijiao {
  margin: 50px auto 0 auto;
  display: flex;
}
.huashu {
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
}
</style>
